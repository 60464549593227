<template>
  <v-container fluid class="mt-1">
    <h3 class="mb-2">Map file headers to IRS fields:</h3>
    <v-row align="center" justify="center">
      <FileUploadColMap
        :show="showModal"
        @close="startMapping"
        @fileUploaded="upload($event)"
        @fileType="fileType = $event"
      />
    </v-row>
    <v-row v-if="!showModal" class="mx-10">
      <v-col cols="12">
        <v-row justify="end">
          <v-btn id="clear" @click="clearColMap">Clear</v-btn>
          <v-btn id="reset" class="ml-7" @click="resetColMap"
            >Reset</v-btn
          ></v-row
        >
      </v-col>
    </v-row>
    <v-row v-if="!showModal">
      <v-layout row wrap justify-end>
        <v-flex shrink>
          <v-col cols="6">
            <h3>IRS&nbsp;Fields</h3>
          </v-col>
        </v-flex>
        <v-col cols="6">
          <h3>File Headers</h3>
        </v-col>
      </v-layout>
    </v-row>

    <v-row v-if="!showModal">
      <v-list-item-content>
        <v-row v-for="(item, i) in retIrsHeader" :key="i">
          <v-layout row justify-end>
            <v-flex shrink>
              <v-col class="mt-5">
                <v-list-item-title> {{ item }} </v-list-item-title>
              </v-col>
            </v-flex>
            <v-col cols="6" class="mt-5 pb-0 pt-0">
              <v-row>
                <v-col sm="auto" md="auto" lg="6">
                  <v-select
                    :id="item"
                    v-model="dropdownChoice.dropdown_id[i]"
                    :items="uniqueUploadedHeaders"
                    :placeholder="dropdownChoice.dropdown_id[i]"
                    outlined
                    dense
                    @change="mapHeaders(item, dropdownChoice.dropdown_id[i])"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-layout>
        </v-row>
      </v-list-item-content>
    </v-row>

    <v-row v-if="!showModal">
      <v-layout row wrap mb-10 mx-10>
        <v-col class="text-right"
          ><v-btn id="submit" @click="submitMapping()">Submit</v-btn>
        </v-col>
      </v-layout>
    </v-row>
  </v-container>
</template>

<script>
import FileUploadColMap from "../components/FileUploadColMap.vue"

export default {
  name: "App",
  components: {
    FileUploadColMap,
  },
  data() {
    return {
      currentMap: {},
      csvFile: null,
      data: {},
      dropdownChoice: {
        dropdown_id: [],
      },
      fileHeaders: [],
      fileType: "",
      headerDict: {},
      retIrsHeader: {},
      showModal: true,
      uniqueUploadedHeaders: [],
    }
  },
  mounted() {
    this.getIrsFields()
  },
  methods: {
    //checkForMatches will autopopulate a dropdown with any value detected in the uploaded headers that matches an already mapped value
    checkForMatches() {
      for (const [key, value] of Object.entries(this.currentMap)) {
        if (this.fileHeaders.includes(value)) {
          var ind = Object.values(this.currentMap).indexOf(value)
          this.dropdownChoice.dropdown_id[ind] = value
          this.mapHeaders(key, value)
        }
      }
    },
    // checkMapping checks the dropdown inputs for blanks and empty selections. If there are either blanks or empties, an alert is issued and false is returned. If there are no issues, return true.
    async checkMapping() {
      for (const [key, value] of Object.entries(this.headerDict)) {
        if (value === "Blank") {
          this.$alert.fire({
            text: "Remove 'Blank' placeholders!",
            icon: "error",
            confirmButtonText: "Back",
            confirmButtonColor: "#D9D9D9",
          })
          return false
        }
      }
      if (Object.entries(this.headerDict).length != this.retIrsHeader.length) {
        this.$alert.fire({
          text: "All fields required!",
          icon: "error",
        })
        return false
      } else {
        return true
      }
    },
    //clearColMap empties all dropdowns, resets the dropdown list, and clears the headerDict dictionary
    clearColMap() {
      this.dropdownChoice.dropdown_id = []
      this.uniqueUploadedHeaders = this.fileHeaders
      this.headerDict = {}
    },
    //getIrsFields retrieves a lis of dictionarys containing model fields (keys) and entries (values) from APHeader MPHeader models.
    getIrsFields() {
      this.$api.get("/revcolmap").then((response, error) => {
        if (response) {
          this.data = JSON.parse(response.data)
        } else {
          console.log(error)
        }
      })
    },
    //setHeadersFromFileType sets the IRS fields column text in the interface depending on the file type
    setHeadersFromFileType() {
      if (this.fileType == "AP") {
        this.currentMap = this.data[0] //APHeader model field: value
        this.retIrsHeader = Object.keys(this.data[0])
      } else {
        this.currentMap = this.data[1]
        this.retIrsHeader = Object.keys(this.data[1]) //MPHeader model field:value
      }
    },
    //getParsedDataHeaders gets the column names from the uploaded file from data in store.js and returns the names in a list. A blank is pushed to the end so that a user can still manipulate the dropdown once all choices have been assigned.
    getParsedDataHeaders() {
      let headers = []
      for (const [header, value] of Object.entries(
        this.$store.getters.getParsedData[0],
      )) {
        headers.push(header)
      }
      headers.push("Blank")
      return headers
    },
    //mapHeaders updates this.headerDict, which contains mappings between the model fields and the uploaded column names which the user would like to establish as equals. This funciton also handles updating the dropdown list.
    mapHeaders(irsHeader, csvHeader) {
      //Update items in this.headerDict
      this.headerDict[irsHeader] = csvHeader

      let filtered = []
      //Values in this.headerDict will be added to the filtered list (if not the blank option).
      for (const [key, value] of Object.entries(this.headerDict)) {
        if (value != "Blank") {
          filtered.push(value)
        }
      }

      //this.uniqueUploadedHeaders is updated to not include items in filtered list
      this.uniqueUploadedHeaders = this.fileHeaders.filter(function (item) {
        return filtered.indexOf(item) === -1
      })
    },
    //parseData reads the uploaded file and returns the results. The results are used to update the store.js value for parsedData. The values from the first line of the parsedData are returned (column names).
    async parseData() {
      const parseFile = (rawFile) => {
        return new Promise((resolve) => {
          this.$papa.parse(rawFile, {
            header: true,
            complete: (results) => {
              resolve(results.data)
            },
          })
        })
      }
      let parsedData = await parseFile(this.csvFile)

      this.$store.dispatch("uploadDataActions", parsedData)
      return this.$store.getters.getParsedData[0]
    },
    //populateNotRequired uses a list of columns provided by the product owner to establish the columns that may not be present in the .csv file used to map values
    populateNotRequired() {
      const apNullableFields = [
        "NameControl",
        "TypeofTIN",
        "PaymentAmount8",
        "PaymentAmountD",
        "PaymentAmountE",
        "PaymentAmountF",
        "PaymentAmountG",
        "PaymentAmountH",
        "PaymentAmountJ",
        "SecondTINNotice",
        "DirectSalesIndicator",
        "FATCAFilingRequirementIndicator",
        "SpecialDataEntries",
        "StateIncomeTaxWithheld",
        "LocalIncomeTaxWithheld",
        "CorrectedReturnIndicator",
        "CombinedFederalStateCode",
      ]

      const mpNullableFields = [
        "RecordSequenceNumber",
        "CombinedFederalStateCode",
      ]
      if (this.fileType == "AP") {
        for (var field of apNullableFields) {
          var ind = Object.keys(this.currentMap).indexOf(field)
          this.dropdownChoice.dropdown_id[ind] = this.currentMap[field]
          this.mapHeaders(field, this.currentMap[field])
        }
      } else {
        for (var mp_field of mpNullableFields) {
          var mp_ind = Object.keys(this.currentMap).indexOf(mp_field)
          this.dropdownChoice.dropdown_id[mp_ind] = this.currentMap[mp_field]
          this.mapHeaders(mp_field, this.currentMap[mp_field])
        }
      }
    },
    //resetColMap resets the dropdown placeholders and dropdown list to their initial values
    resetColMap() {
      this.checkForMatches()
      this.populateNotRequired()
    },
    //startMapping is called once a file has been uploaded. It handles hiding the modal and calls the functions required to populate the column mapping interface.
    async startMapping() {
      this.showModal = false
      this.setHeadersFromFileType()
      let data = await this.parseData()
      this.fileHeaders = this.getParsedDataHeaders()
      this.uniqueUploadedHeaders = this.fileHeaders
      this.populateNotRequired()
      this.checkForMatches()
    },
    //submitMapping will submit the column mappings. The payload is the file type and this.headerDict.
    async submitMapping() {
      let submit = await this.checkMapping()

      if (submit) {
        let formData = new FormData()
        formData.append("fileType", this.fileType)
        formData.append("mappedHeaders", JSON.stringify(this.headerDict))
        this.$api
          .put("/colmap", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$alert
              .fire({
                title: "Success",
                text:
                  "The column mapping for " +
                  this.fileType +
                  " files has been updated.",
                icon: "success",
                confirmButtonColor: "#f19e1f",
                confirmButtonText: "Next",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$router.push("/reviewcolmap")
                }
              })
          })
      }
    },
    //upload(e) is the custom bind event FileUploadColMap emits to send the uploaded csv file to the parent element.
    upload(e) {
      this.csvFile = e
    },
  },
}
</script>

<style>
input::placeholder {
  color: black !important;
  font-weight: normal !important;
  opacity: 1 !important;
}
</style>
