<template>
  <v-card v-if="show" elevation="3" width="25%">
    <v-card-text>
      <v-row class="ml-5">
        <v-radio-group v-model="fileType" row>
          <h4>File type: &nbsp;&nbsp;&nbsp;</h4>
          <v-radio id="file-ap" label="AP" value="AP"></v-radio>
          <v-radio id="file-mp" label="MP" value="MP"></v-radio>
        </v-radio-group>
      </v-row>

      <v-row class="ml-5 mr-5">
        <v-col cols="12">
          <v-file-input
            id="file-input"
            v-model="csvFile"
            accept=".csv"
            label="Choose a CSV file"
            outlined
            dense
          >
          </v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn
            id="map"
            color="#1e5288"
            class="white--text"
            @click="checkFile()"
          >
            Map
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FileUploadColMap",
  props: { show: { type: Boolean, default: true } },
  data: function () {
    return {
      fileType: null,
      csvFile: null,
    }
  },
  methods: {
    //checkFile checks that a .csv file has been uploaded and that a file type has been selected from the radio button group.
    checkFile() {
      if (!this.csvFile) {
        this.$alert.fire({
          text: "No file chosen! Please upload a file.",
          icon: "error",
        })
      } else if (this.csvFile.type != "text/csv") {
        this.$alert.fire({
          text:
            this.csvFile.type +
            " is not an accepted file type. Please enter a .csv file.",
          icon: "error",
        })
      } else if (this.fileType == null) {
        this.$alert.fire({
          text: "No file type selected. Please choose a file type.",
          icon: "error",
        })
      } else {
        this.close()
      }
    },
    //close emits to the parent object and sends this.csvFile and this.fileType from child to parent
    close() {
      this.$emit("fileUploaded", this.csvFile)
      this.$emit("fileType", this.fileType)
      this.$emit("close")
    },
  },
}
</script>
